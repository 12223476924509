.cardContent {
    display: flex;
}
.cardContentContainner {
    margin-top: 30px;
    border: 1px solid #DCDFE6;
    padding: 30px;
    border-radius: 8px;
}

.cardImage {
    width: 224px;
    height: 170px;
    object-fit: cover;
}

.cardInfo {
    flex: 2;
    padding-left: 20px;
}

.cardScore {
    display: inline-flex;
    flex-wrap: wrap;
    width: 90%;
}

.cardScoreItem {
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 400;
    color: #696E7E;
    line-height: 22px;
    display: flex;
    min-width: 33%;
}

.cardScoreItemIcon {
    width: 20px;
    height: 20px;
    margin-left: 30px;
}

.cardScoreItemText {
    margin-left: 20px;
    font-size: 16px;
    font-weight: 600;
    /*color: #40A6FF;*/
    line-height: 22px;
}

.cardHospitalTitle {
    font-size: 20px;
    font-weight: 600;
    color: #162533;
    line-height: 28px;
}

.cardHospitalLevel {
    margin-top: 12px;
    font-size: 16px;
    font-weight: 400;
    color: #696E7E;
    line-height: 22px;
}

.cardHospitalCommented {
    font-size: 20px;
    font-weight: 400;
    color: #696E7E;
    line-height: 22px;
}

.cardHospitalTotal {
    margin-top: 42px;
    font-size: 16px;
    font-weight: 400;
    color: #162533;
    line-height: 22px;
    display: flex;
}

.cardHospitalTotalStatus {
    font-size: 16px;
    /*font-weight: 600;*/
    color: #40A6FF;
    line-height: 20px;
}

.cardHospitalLabel {
    display: flex;
    margin-top: 18px;
    flex-wrap: wrap;
    max-width: 410px;
}

.cardHospitalLabelItem {
    margin-right: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #696E7E;
    line-height: 20px;
    border-radius: 2px;
    border: 1px solid #DCDFE6;
    padding: 3px 7px;
}

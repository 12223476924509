.container {
    height: 100%;
    width: 64%;
    margin: 126px auto;
    background-color: white;
}

.ant-tabs-nav{
    margin: 0;
}

:global .ant-tabs-nav{
    margin: 0 !important;
}

.pagination {
    margin-top: 63px;
    float: right;
}

.tabContent {
    padding: 0 50px;
    padding-bottom: 56px;
}

.emptyView {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
  }
.emptyIcon {
    background-color: white;
    width: 331px;
    height: 196px;
    margin-top: 84px;
}
.emptyText {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 400;
    color: #162533;
    line-height: 22px;
}

.empytBtn {
    margin-top: 50px;
    border-radius: 4px;
    width: 270px;
    height: 50px;
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
}
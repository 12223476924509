.container {
    padding: 20px;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    display: flex;
    margin-top: 29px;
}

.container-title {
    cursor: pointer;
    font-size: 20px;
    font-weight: 600;
    color: #333333;
    line-height: 28px;
}

.card-left-bottom {
    display: flex;
}
.card-left-footer {
    margin-top: 15px;
}

.card-delete-btn {
    margin-right: 20px;
}

.card-status {
    margin-right: 20px;
}

.card-time {
    font-size: 16px;
    font-weight: 400;
    color: #696E7E;
    line-height: 22px;
}

.card-review {
    font-size: 16px;
    font-weight: 600;
    color: #40A6FF;
    line-height: 22px;
}

.card-success {
    font-size: 16px;
    font-weight: 600;
    color: #162533;
    line-height: 22px;
}

.card-failed {
    font-size: 16px;
    font-weight: 600;
    color: #F16765;
    line-height: 22px;
}

.card-delete {
    font-size: 16px;
    font-weight: 600;
    color: #162533;
    line-height: 22px;
}

.container-left {
    width: 80%;
}
.container-left-footer {
    display: flex;
    height: 85%;
    justify-content: space-between;
}

.image-card {
    width: 240px;
    height: 180px;
    background-size: cover;
    background-position: center center;
}
.card-btn-class {
    width: 120px;
    height: 40px;
    font-size: 16px;
    font-weight: 400;
    color: #696E7E;
    border-radius: 4px;
}

.card-btn-review {
    color: #FFFFFF;
}

.card-left-flex{
    align-self: flex-end;
}

.margin-right-20 {
    margin-right: 20px;
}
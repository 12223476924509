.five-contain {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    padding-top: 100px;
}
.fifth-wrap {
    width: 100%;
    height: 100%;
    position: relative;
}



.fifth-img-wrap img {
    width: 494px;
    height: 603px;
}

.fifth-left {
    position: absolute;
    left: 0;
    top: 30%;
    z-index: 9;
}



.fifth-title-wrap {
    animation: fifthtitle 2s;
    -webkit-animation: fifthtitle 2s;
}

@keyframes fifthtitle {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fifthtitle {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media screen and (min-width: 768px) {
    .fifth-img-wrap {
        position: absolute;
        right: 0;
        top: 5%;
        
    }
    .fifth-title {
        text-align: left;
        font-size: 60px;
        color: #40A6FF;
        font-weight: 600;;
    }
    
    .fifth-sub {
        text-align: left;
        font-size: 24px;
        color: #333333;
        margin-top: 0.5em;
    }
}

@media screen and (min-width: 1441px) {
    .fifth-img-wrap {
        position: absolute;
        right: 0;
        top: 10%;
        
    }
    .fifth-title {
        text-align: left;
        font-size: 80px;
        color: #40A6FF;
        font-weight: 600;;
    }
    
    .fifth-sub {
        text-align: left;
        font-size: 30px;
        color: #333333;
        margin-top: 0.5em;
    }
}
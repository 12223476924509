.ReactModalPortal .ReactModal__Overlay.ReactModal__Overlay--after-open{
  background-color: transparent !important;
  z-index: 9999;
}

.wechat-login-iframe {
  height: 390px;
  padding-left: 10px;
}

.login-modal{
  /* width: 391px; */
  /* height: 450px; */
  background: #ffffff;
  border-radius: 8px;
  padding-top: 26px;
  padding-left: 11px;
  padding-right: 11px;
}
.close-wrap {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.close-item {
  padding: 16px;
  padding-bottom: 0;
  margin-top: 10px;
  margin-right: 11px;
  margin-bottom: 2px;
}
.close-icon{
  width: 16px;
  height: 16px;
}
.content-wrap {
  padding-left: 35px;
  padding-right: 35px;
}
.logo-wrap{
  display: flex;
  align-items: center;
  margin-bottom: 33px;
}
.login-icon {
  width: 42px;
  height: 42px;
  margin-right: 8px;
}
.title-wrap {
  display: flex;
  flex-direction: column;
}
.modal-title {
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
}
.sub-title {
  font-size: 14px;
  font-weight: 400;
  color: #696E7E;
  line-height: 20px;
}
.input-wrap {
  width: 100%;
  height: 50px;
  border: 1px solid #DCDFE6;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  overflow: hidden;
}
.input-wrap-bottom {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  border: 1px solid #DCDFE6;
  border-top: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
}
.num-input {
  width: 305px;
  height: 50px;
  font-size: 18px;
  color: #162533;
  border: none;
  outline: none;
  padding: 0;
  padding-left: 14px;
}
.code-input {
  width: 203px;
  height: 50px;
  font-size: 18px;
  color: #162533;
  border: none;
  outline: none;
  padding: 0;
  padding-left: 14px;
}
.line-icon {
  width: 1px;
  height: 20px;
  background-color: #DCDFE6;
}
.code-btn {
  width: 100px;
  height: 50px;
  font-size: 14px;
  color: #162533;
  background-color: transparent;
  border: none;
  outline: none;
  cursor:pointer;
}
.err-tip {
  height: 17px;
  font-size: 12px;
  font-weight: 600;
  color: #F16765;
  line-height: 17px;
  margin-top: 11px;
}
.submit-button {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 16px;
  color: #FFFFFF;
  background: #40A6FF;
  border-radius: 4px;
  border: none;
  outline: none;
  margin-top: 15px;
  cursor:pointer;
}
.another-way {
  text-align: center;
  font-size: 14px;
  color: #A4ACB4;
  margin-top: 30px;
}
.wechat-wrap {
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 13px;
}
.wechat-item {
  width: 37px;
  height: 30px;
  cursor:pointer;
}
.wechat {
  width: 100%;
  height: 100%;
}

.num-input::-webkit-outer-spin-button,
.num-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none; 
    margin: 0; 
}
.num-input {
  -moz-appearance:textfield;
}
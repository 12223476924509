/* .ant-pagination-options {
  display: none;
} */

.ant-form-item-label {
  width: 0;
}

.container {
  width: 100%;
  height: 100%;
  padding-top: 100px;
}
.news_contant {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 650px;
  border-radius: 8px;
  margin-top: 26px;
  overflow-y: scroll;
}
.header_wrap {
  width: 1100px;
  display: flex;
  font-size: 20px;
  font-weight: 600;
  color: #162533;
  line-height: 28px;
  border-bottom: 1px solid #DCDFE6;
  padding-top: 53px;
  padding-bottom: 15px;
  margin: 0 50px 17px 50px;
}
.news_wrap {
  width: 1200px;
  padding-bottom: 24px;
}
.news_header {
  display: flex;
  align-items: center;
  margin-left: 50px;
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  overflow: hidden;
  margin-right: 12px;
}
.name_wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.user_name {
  font-size: 14px;
  color: #162533;
  line-height: 20px;
}
.user_time {
  font-size: 12px;
  color: #A4ACB4;
  line-height: 17px;
}
.reply_wrap {
  padding-left: 102px;
  margin-top: 10px;
}
.reply_tip {
  font-size: 16px;
  font-weight: 600;
  color: #162533;
  line-height: 22px;
}
.reply_del {
  font-size: 16px;
  color: #162533;
  line-height: 22px;
}
.like_reply {
  display: flex;
  align-items: center;
  padding-left: 102px;
  margin-top: 11px;
}
.like_wrap {
  display: flex;
  align-items: center;
  margin-right: 48px;
  cursor:pointer;
}
.like_icon {
  width: 16px;
  height: 14px;
  margin-right: 6px;
}
.like_text {
  font-size: 14px;
  color: #162533;
  line-height: 20px;
  cursor:pointer;
}
.note_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1048px;
  height: 50px;
  border-radius: 2px;
  background-color: #F3F5F8;
  margin-left: 102px;
  margin-top: 18px;
}
.input_wrap {
  width: 1048px;
  margin-left: 102px;
  margin-top: 16px;
}
.btn_wrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.btn_item {
  width: 120px;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  color: white;
  text-align: center;
  background-color: #40A6FF;
  border-radius: 4px;
  padding: 0;
  margin-left: 20px;
  border: none;
  outline: none;
  cursor:pointer;
}
.note_text {
  font-size: 16px;
  color: #696E7E;
  line-height: 22px;
  margin-left: 20px;
}
.grey_goin {
  width: 8px;
  height: 12px;
  margin-right: 19px;
}
.page_view {
  margin: 0 auto;
  width: 1200px;
  display: flex;
  justify-content: flex-end;
  padding-top: 50px;
}
.empty_view {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
}
.empty_icon {
  width: 331px;
  height: 196px;
  margin-top: 208px;
}
.emptyText {
  font-size: 16px;
  color: #162533;
  line-height: 22px;
  margin-top: 10px;
}


.imgView {
  width: 1200px;
  height: 800px;
  position: relative;
}
.imgItem {
  width: 100%;
  height: 100%;
}
.closeIcon {
  width: 36px;
  height: 36px;
  position: absolute;
  right: 16px;
  top: 16px;
}
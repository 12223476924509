.container {
    width: 100%;
    height: 100%;
    padding-top: 100px;
}
.user_wrap {
    margin: 0 auto;
    width: 1200px;
    /*height: 569px;*/
    /*background: #FFFFFF;*/
    border-radius: 8px;

}
.user_wrap_inner {
    /*margin: 0 auto;*/
    /*width: 1200px;*/
    /*height: 569px;*/
    background: #FFFFFF;
    /*border-radius: 8px;*/
    margin-top: 28px;
    padding-top: 40px;
    padding-bottom: 55px;
    padding-left: 50px;
    padding-right: 50px;
}
.editContainer {
    background: #FFFFFF;
    /*border-radius: 8px;*/
    margin-top: 28px;
    padding-top: 40px;
    padding-bottom: 55px;
    padding-left: 50px;
    padding-right: 50px;
}
:global .ant-form-item{
     margin-bottom: 0;
}
.line1 {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
.title_text {
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #162533;
}
.trash_text {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #696E7E;
}
.rightArrow {
    width: 14px;
    height: 14px;
    margin-left: 2px;
    margin-bottom: 1px;
}
.titlt_input {
    height: 55px;
}
.titlt_input input{
    font-size: 30px;
}
.title_shenyu {
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #A4ACB4;
    line-height: 28px;
}
.checkItem {
    width: 100px;
}
.divider {
    margin: 0 0;
}
.line2 {
    display: inline-flex;
    width: 100%;
    align-items: center;
    height: 68px;
}
.type_title {
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #162533;
    minWidth: 100px;
}
.type_title :after {
    content: none;
}
.type_title :before {
    display: none !important;
}
.titlt_input_type {
    width: 240px;
}

.divider_type {
    margin: 0 0;
}
.colContent {
   width: 95% !important;
}
.titleWithMar {
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #162533;
    margin-bottom: 7px;
}
.titleExtra {
    margin-left: 24px;
    font-size: 13px;
    font-weight: 400;
    font-family: none;
}
.titleMoney {
    color: #FF4340;
    font-size: 15px;
}
.choose_row {
    margin-bottom: 20px;
}
.fabuLine {
    height: 70px;
    background-color: white;
    width: 100%;
    margin-top: 53px;
}
.fabuLineContent {
    margin: 0 auto;
    width: 1200px;
    /*background-color: red;*/
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
.saveAuto {
    margin-right: 20px;
    color: #40A6FF;
    font-size: 16px;
}
.saveBtn {
    width: 187px;
    height: 50px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #162533;
    font-size: 16px;
    cursor:pointer;
}
.btnMar {
    margin: 0 15px;
}
.fabuBtn {
    width: 289px;
    background: #40A6FF;
    border-radius: 4px;
    color: #ffffff;
}


.real_text {
    font-size: 16px;
    font-weight: 400;
    color: #696E7E;
    line-height: 22px;
}
.real_btn_wrap {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
.real_btn {
    font-size: 14px;
    color: white;
    background-color: #40A6FF;
    border-radius: 4px;
    cursor: pointer;
    padding: 4px 8px;
    border: none;
    outline: none;
}
.iframeModal :global .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
}
.unitText {
    line-height: 32px;
    padding: 0 12px;
}

body{
    font-family: arial;
}

.custom-section{
    text-align: center;
    font-size: 4em;
    color: #fff;
}
.home-wrap {
    display: flex;
    flex: 1;
    background-color: white;
}





#fp-nav ul li a span {
    background: #DCDFE6;
    width: 12px;
    height: 12px;
    margin: -6px 0 0 -6px;
}

#fp-nav ul li:hover a.active span,
#fp-nav ul li a.active span,
.fp-slidesNav ul li:hover a.active span,
.fp-slidesNav ul li a.active span {
    background: #40A6FF;
}





@media screen and (min-width: 768px) {
    .home-content {
        max-width: 1000px;
        margin: 0 auto;
    }
    #fp-nav.fp-right {
        right: 9%;
    }
}

@media screen and (min-width: 1441px) {
    #fp-nav.fp-right {
        right: 10%;
    }
    .home-content {
        max-width: 1200px;
        margin: 0 auto;
    }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: black !important;
}
.menu {
  width: 100%;
  height: 100px;
  background:rgba(255,255,255,1);
  box-shadow:0px 3px 6px 0px rgba(217,217,217,0.5);
  position: fixed;
  top: 0;
  z-index: 99;
}
@media screen and (min-width: 768px) {
  .menu_wrap {
    display: flex;
    width: 1000px;
    height: 100%;
    justify-content: space-between;
    margin: 0 auto;
  }

}

@media screen and (min-width: 1441px) {
  .menu_wrap {
    display: flex;
    width: 1200px;
    height: 100%;
    justify-content: space-between;
    margin: 0 auto;
  }
}

.menu_left {
  display: flex;
  align-items: center;
}
.menu_right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.logo{
  width: 58;
  height: 58;
  margin-right: 13px;
}

.title {
  font-size: 30px;
  font-weight: 600;
  color: #40A6FF;
}
.actions{
  margin: 0;
  padding: 0;
  display:flex;
  height: 100%;
  list-style-type: none;
}
.actions li {
  height: 100%;
  border-bottom-style: solid;
  border-bottom-width: 6px;
  border-bottom-color: #FFFFFF;
  padding: 0 0.5em;
  margin-right: 2em;
}
.actions li.active {
  border-bottom-style: solid;
  border-bottom-width: 6px;
  border-bottom-color: #40A6FF;
}
.sign_btn {
  font-size: 1em;
  color: #fff;
  padding: 0.5em 1em;
  background: #40A6FF;
  border-radius: 8px;
  margin-left: 2em;
  border: none;
  outline: none;
  cursor:pointer;
}
.link_word {
  display:flex;
  height: 100%;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  color: #162533;
  text-decoration:none;
}
.user_wrap {
  height: 100%;
  position: relative;
}
.user_info {
  display: flex;
  align-items: center;
  height: 100%;
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 11px;
  overflow: hidden;
}
.center_text {
  font-size: 16px;
  line-height: 22px;
  color: #162533;
}
.modal_view {
  width: 137px;
  height: 142px;
  position: absolute;
  top: 61px;
  
}
.modal_item {
  width: 100%;
  height: 43px;
  border: 1px solid #DCDFE6;
  border-top: none;
  background-color: white;
  position: relative;

}
.modal_item_top {
  width: 100%;
  height: 43px;
  border: 1px solid #DCDFE6;
  border-top: none;
  background-color: white;
  margin-top: 12px;
}
.modal_text {
  width: 100%;
  height: 100%;
  display: block;
  font-size: 16px;
  text-align: center;
  line-height: 43px;
  text-decoration: none;
  color: #162533;
}
.new_text {
  width: 27px;
  height: 18px;
  line-height: 18px;
  font-size: 12px;
  text-align: center;
  color: white;
  background: #F16765;
  border-radius: 9px 9px 9px 0px;
  position: absolute;
  right: 9px;
  top: -2px;
}
.triangle {
  width: 137px;
  height: 13px;
  position: absolute;
}
.triangle_icon {
  width: 12px;
  height: 7px;
  margin-left: 10px;
}
.transfromY {
  width: 12px;
  height: 7px;
  margin-left: 10px;
  transform: rotate(180deg);
  /* animation: spin 1s linear 1s 2;
  -webkit-animation: spin 1s linear 1s 2; */
}
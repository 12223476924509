.ReactModalPortal .ReactModal__Overlay.ReactModal__Overlay--after-open{
    background-color: transparent !important;
    z-index: 9999;
}
.protocol_modal {
    width: 593px;
    height: 600px;
    background: #ffffff;
    border-radius: 8px;
}
.close_wrap {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}
.close_item {
    padding: 16px;
    padding-bottom: 0;
    margin-top: 5px;
    margin-right: 5px;
    margin-bottom: 4px;
}
.close_icon{
    width: 16px;
    height: 16px;
}
.title{
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #162533;
    line-height: 28px;
}
.content {
    width: 521px;
    height: 360px;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
    margin-left: 35px;
}
.content::-webkit-scrollbar { 
    width: 0 !important 
}
.btn_wrap {
    display: flex;
    flex: 1;
    justify-content: center;
    margin-top: 46px;
}
.confirm_btn {
    width: 319px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    font-size: 16px;
    color: #FFFFFF;
    background: #40A6FF;
    border-radius: 4px;
    border: none;
    outline: none;
}
.sub_title {
    font-size: 16px;
    font-weight: 600;
    color: #162533
}
.content_del {
    font-size: 16px;
    line-height: 24px;
    color: #162533
}
.sub_content {
    margin-left: 28px;
}
.sub_sub_content {
    margin-left: 56px;
}
.imgMenu {
    color: #162533;
    text-align: center;
}
.imgMenu>:first-child {
    border-bottom: 1px solid #DCDFE6;
}
.imgMenuItem {
    background-color: #ffffff;
    height: 42px;
    line-height: 42px;
    width: 100%;
}
:global .bf-container .public-DraftEditor-content {
    min-height: 210px;
}
:global .bf-dropdown .dropdown-handler .bfi-drop-down {
    width: auto;
}
:global .bf-dropdown .dropdown-content {
    width: 167px;
}
:global .bf-dropdown .dropdown-content .dropdown-arrow {
    background-color: white;
}
:global .bf-dropdown .dropdown-content-inner {
    background-color: white;
}
.imgModalItem {
    width: 193px;
    height: 193px;
    border-radius: 3px;
    border: 1px solid #DCDFE6;
}
.imgModalItemTxt {
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #162533;
    line-height: 20px;
    text-align: center;
    margin-top: 14px;
}
:global .ant-modal-body {
    padding: 35px;
}
.picTit {
    font-size: 20px;
    font-weight: 600;
    color: #333333;
    line-height: 28px;
    margin-top: 10px;
}
.picSub {
    font-size: 16px;
    font-weight: 400;
    color: #696E7E;
    line-height: 22px;
    margin-top: 17px;
    margin-bottom: 29px;
}
:global .ant-upload {
    width: 100%;
    height: 100%;
}
.uploadButton {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
}
.titleLeft {
    color: #696E7E;
    font-size: 14px;
    margin-bottom: 30px;
}
.titleLeftRig {
    color: #162533;
    font-weight: 600;
}
.imgCon {
    width: 180px;
    height: 180px;
    position: relative;
}
.imgRight {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    font-size: 20px;
    border-radius: 50%;
    border: 1px solid #696E7E;
}
.btmSty {
    height: 100px;
}
.btmBtn {
    width: 319px;
    height: 50px;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    color: #162533;
}
.btmCol {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.btmBtnSure {
    width: 319px;
    height: 50px;
    background: #40A6FF;
    border-radius: 4px;
    color: #FFFFFF;
    border-color: transparent;
}
.customEditor :global .bf-content * {
    line-height: 24px;
}

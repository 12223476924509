.detailContainer {
    height: 100%;
    width: 72%;
    margin: 126px auto;
    background-color: white;
    padding: 40px 50px;
}

.detailTitle {
    font-size: 34px;
    font-weight: 600;
    color: #162533;
    line-height: 48px;
}

.detailUserInfo {
    display: flex;
    margin-top: 10px;
}

.detailUserAvatar {
    width: 30px;
    height: 30px;
    border-radius: 30px;
}

.detailUserName {
    font-size: 16px;
    font-weight: 400;
    color: #162533;
    line-height: 30px;
    margin-left: 10px;
}

.detailUserReadCount {
    font-size: 14px;
    font-weight: 400;
    color: #A4ACB4;
    line-height: 30px;
    margin-left: 30px;
}

.detailUserUpdateTime {
    font-size: 14px;
    font-weight: 400;
    color: #A4ACB4;
    line-height: 30px;
    margin-left: 33px;
}

.detailTabs {
    margin-top: 40px;
}

.tabTitle {
    font-size: 20px;
    font-weight: 600;
    color: #162533;
    line-height: 28px;
}

.tabCreateTime {
    font-size: 16px;
    font-weight: 400;
    color: #696E7E;
    line-height: 22px;
}

.detailTabPane {
    display: flex;
    flex-direction: row;
    /*justify-content: space-between;*/
    flex-wrap: wrap;
}
.detailTabPaneItem {
    /*flex-grow: 1;*/
}

.detailTabPaneItemMolecular {
    margin-top: 30px;
    display: flex;
    font-size: 16px;
    font-weight: 400;
    color: #162533;
    /*line-height: 22px;*/
    min-width: 33%;
    align-items: center;
}

.detailTabPaneItemAtom {
    font-size: 16px;
    font-weight: 400;
    color: #696E7E;
    margin-right: 16px;
}
.questionSpan {
    padding: 0 10px;
    height: 32px;
    line-height: 32px;
    margin-right: 24px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
}

.detailTypes {
    margin-top: 46px;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    color: #696E7E;
    line-height: 20px;
    margin-bottom: 30px;
    flex-wrap: wrap;
}

.detailTypesItem {
    margin-right: 14px;
}

.detailEndFlag {
    text-align: center;
    margin-top: 50px;
    font-size: 16px;
    font-weight: 400;
    color: #879099;
    line-height: 22px;
}

:global .ant-tabs-ink-bar {
    height: 8px !important;
    color: #40A6FF;
}

.page_view {
    display: flex;
    justify-content: flex-end;
    padding-top: 50px;
  }
.comments_reply {
    border: 1px solid #DCDFE6;
    padding: 20px;
    margin-top: 30px;
    margin-left: 52px;
}
.comments_count {
    font-size: 20px;
    font-weight: 600;
    color: #162533;
    line-height: 28px;
    border-bottom: 1px solid #DCDFE6;
    margin-top: 72px;
    padding-bottom: 15px;
}
.reply_count {
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    line-height: 22px;
}
.container {
    width: 100%;
    height: 100%;
}

.second-contain {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    padding-top: 100px;
}
.second-wrap {
    width: 100%;
    height: 100%;
    position: relative;
}




.second-img-wrap img {
    width: 508px;
    height: 603px;
}

.second-right {
    position: absolute;
    right: 0;
    top: 30%;
}





.second-title-wrap {
    animation: secondtitle 2s;
    -webkit-animation: secondtitle 2s;
}

@keyframes secondtitle {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes secondtitle {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media screen and (min-width: 768px) {
    .second-img-wrap {
        position: absolute;
        left: 0;
        top: 5%;
    }
    .second-title {
        text-align: left;
        font-size: 60px;
        color: #40A6FF;
        font-weight: 600;;
    }
    .second-sub {
        text-align: left;
        font-size: 24px;
        color: #333333;
        margin-top: 0.5em;
    }
}

@media screen and (min-width: 1441px) {
    .second-img-wrap {
        position: absolute;
        left: 0;
        top: 10%;
    }
    .second-title {
        text-align: left;
        font-size: 80px;
        color: #40A6FF;
        font-weight: 600;;
    }
    .second-sub {
        text-align: left;
        font-size: 30px;
        color: #333333;
        margin-top: 0.5em;
    }
}
html,body{
  height: 100%;
  margin: 0;
}
body {
  background-color: rgb(220,223,230,0.3) !important;
}
.container {
  width: 100%;
  height: 100%;
  padding-top: 100px;
}
.user_wrap {
  margin: 0 auto;
  width: 795px;
  background: #FFFFFF;
  border-radius: 8px;
  margin-top: 28px;
}
.user_info {
  padding: 50px 52px 35px 52px;
}
.title_wrap {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #DCDFE6;
  padding-bottom: 15px;
}
.title {
  font-size: 20px;
  font-weight: 600;
  color: #162533;
  line-height: 28px;
  margin-right: 9px;
}
.sub_title {
  font-size: 14px;
  font-weight: 400;
  color: #696E7E;
  line-height: 20px;
}
.user_del {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 19px;
  padding: 20px 14px 19px 14px;
  background-color: #F3F5F8;
  border-radius: 5px;
}
.user_title {
  font-size: 16px;
  font-weight: 600;
  color: #162533;
  line-height: 22px;
}
.flex_box {
  display: flex;
  align-items: center;
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 9px;
  overflow: hidden;
}
.user_text {
  font-size: 16px;
  font-weight: 400;
  color: #696E7E;
  line-height: 22px;
}
.input_wrap {
  display: flex;
  align-items: center;
  width: 692px;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #A4ACB4;
  overflow: hidden;
  margin-top: 20px;
}
.input_title {
  display: inline-block;
  width: 105px;
  font-size: 16px;
  font-weight: 600;
  color: #162533;
  line-height: 22px;
  padding-left: 20px;
}
.input_view {
  width: 564px;
  height: 100%;
  line-height: 50px;
  font-size: 16px;
  color: #162533;
  border: none;
  outline: none;
  padding: 0;
}
.err_tip {
  display: block;
  height: 25px;
  font-size: 14px;
  color: #F16765;
  line-height: 20px;
  margin-top: 7px;
}
.save_btn {
  width: 187px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 16px;
  color: white;
  background-color: #40A6FF;
  /* background-color: #DCDFE6; */
  border-radius: 5px;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  margin-top: 12px;
  cursor:pointer;
}
.wechat_wrap {
  display: flex;
  align-items: center;
  margin-top: 24px;
}
.wechat {
  width: 37px;
  height: 30px;
  margin-right: 11px;
}
.wechat_tip {
  font-size: 16px;
  color: #162533;
  line-height: 22px;
}
.wechat_btn {
  width: 100px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  font-size: 16px;
  color: white;
  background-color: #40A6FF;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
}
.cardContent {
    display: flex;
}
.cardContentContainner {
    margin-top: 30px;
    border: 1px solid #DCDFE6;
    padding: 30px;
    border-radius: 8px;
}


.cardImage {
    width: 140px;
    height: 140px;
    border-radius: 70px;
    margin-right: 52px;
    margin-left: 32px;
    margin-top: 13px;
    object-fit: cover;
}

.cardInfo {
    flex: 2;
    padding-left: 20px;
}

.cardScore {
    display: inline-flex;
    flex-wrap: wrap;
    width: 90%;
}

.cardScoreItem {
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 400;
    color: #696E7E;
    line-height: 22px;
    display: flex;
    min-width: 33%;
}

.cardScoreItemIcon {
    width: 20px;
    height: 20px;
    margin-left: 30px;
}

.cardScoreItemText {
    margin-left: 20px;
    font-size: 16px;
    font-weight: 600;
    color: #40A6FF;
    line-height: 22px;
}

.cardDoctorTitle {
    font-size: 20px;
    font-weight: 600;
    color: #162533;
    line-height: 28px;
}

.cardDoctorLevel {
    margin-top: 12px;
    font-size: 16px;
    font-weight: 400;
    color: #696E7E;
    line-height: 22px;
}

.cardDoctorCommented {
    font-size: 20px;
    font-weight: 400;
    color: #696E7E;
    line-height: 22px;
}

.cardDoctorTotal {
    margin-top: 42px;
    font-size: 16px;
    font-weight: 400;
    color: #162533;
    line-height: 22px;
    display: flex;
}

.cardDoctorTotalStatus {
    font-size: 16px;
    /*font-weight: 600;*/
    color: #40A6FF;
    line-height: 20px;
}

.cardDoctorLabel {
    display: flex;
    margin-top: 18px;
    flex-wrap: wrap;
    max-width: 410px;
}

.cardDoctorLabelItem {
    margin-right: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #696E7E;
    line-height: 20px;
    border-radius: 2px;
    border: 1px solid #DCDFE6;
    padding: 3px 7px;
}

.six-contain {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding-top: 100px;
}

.contain-wrap {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.download-title {
  font-size: 40px;
  font-weight: bolder;
  color: #333333;
  letter-spacing: 0;

}

.download-wrap {
  display: flex;
  width: 600px;
  justify-content: space-between;
  margin-top: 3em;
}

.download-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.download-text {
  font-size: 1.4em;
  color: #333333;
  letter-spacing: 0;
  margin-bottom: 14px;
}

.download-icon {
  width: 200px;
  height: 200px;
}

.pact-wrap {
  display: flex;
  width: 10em;
  justify-content: space-between;
  margin-top: 160px;
}

.pact-text {
  font-size: 1em;
  color: #40A6FF;
  letter-spacing: 0;
  cursor:pointer;
}

.footer-wrap {
  margin-top: 30px;
  margin-bottom: 2em;
}

.info-text {
  font-size: 0.8em;
  color: #333333;
  letter-spacing: 1px;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .download-title{
    margin-top: 50px;
  }
}

@media screen and (min-width: 1441px) {
  .download-title{
    margin-top: 0;
  }
}
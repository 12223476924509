/*:global .ant-modal-body {*/
/*    padding: 0 !important;*/
/*}*/
/*:global .ant-modal-content {*/
/*    background-color: transparent !important;*/
/*}*/
.showMobilePreview {
    /*z-index: 9999;*/
    width: 391px;
    height: 768px;
    /*position: absolute;*/
    /*left: 50%;*/
    /*top: 50%;*/
    /*transform: translate(-50%, -50%);*/
    /*opacity: 1;*/
    text-align: center;
}
.mobile_preview_header {
    /*display: block;*/
    /*position: absolute;*/
    /*top: 0;*/
    /*left: 0;*/
    height: 40px;
    width: 387px;
    background: #eeeff2;
    text-align: center;
    line-height: 40px;
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
}

.mobile_preview_header_icon {
    display: inline-block;
    width: 65px;
    height: 10px;
    background: #c8c9cc;
    border-radius: 9px;
    vertical-align: middle;
    margin-top: 18px;
}

.mobile_preview_frame {
    width: 387px;
    min-height: 294px;
    height: 667px;
    max-height: calc(100vh - 166px);
    /*top: 40px;*/
    /*left: 0;*/
    border: 6px solid #eeeff2;
    /*position: relative;*/
    background-color: #fff;
    display: block;


    text-align: left;
    padding: 0 16px;
    /*height: 637px;*/
    overflow: scroll;
}

.YuFrameMobilePreview {
    border: none;
    width: 375px;
    height: 100%;
}

.mobile_preview_footer {
    /*display: block;*/
    /*position: absolute;*/
    /*bottom: 10px;*/
    /*left: 0;*/
    height: 62px;
    width: 387px;
    background: #eeeff2;
    text-align: center;
    line-height: 45px;
    border-bottom-right-radius: 50px;
    border-bottom-left-radius: 50px;
}

.mobile_preview_footer_icon {
    display: inline-block;
    width: 43px;
    height: 43px;
    background: #c8c9cc;
    border-radius: 50%;
    vertical-align: middle;
}
.innerhtml {
    /*text-align: left;*/
    padding: 16px 0;
    /*height: 637px;*/
    /*overflow: scroll;*/
}
.innerhtml img {
    max-width: 343px;
    height: auto !important;
}
.headerWrap {
    padding: 26px 10px 0 10px;
    border-bottom: 1px solid #E5E5E5;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.headerTitle {
    /*width: 340px;*/
    /*font-size: 21px;*/
    font-weight: 600;
    font-size: 17px;
}
.treatmentItem {
    border-bottom: 4px solid #40A6FF;


}
.treatmentTime {
    fontSize: 12px;
    margin-bottom: 4px;
}
.infoWrap {
    margin-top: 17px;
    display: inline-flex;
    flex-wrap: wrap;
}
.cureItem {
    display: inline-flex;
    margin-bottom: 4px;
    min-width: 50%;
}
.cureTitle {
    font-size: 13px;
    margin-right: 4px;
    color: #A4ACB4;
}
.cureDetail {
    font-size: 13px;
    color: #162533;
}
.cureInfoFlex {
    display: inline-flex;
    margin-bottom: 4px;
}
.cureInfo {
    width: 50%;
    display: inline-flex;
}
.evalWrap {
    margin: 20px 0px 0 0px;
    border: 1px solid rgba(64,166,255,0.5);
    border-radius: 8px;
}
.evalTop {
    margin-top: 17px;
    margin-left: 14px;
    display: inline-flex;
    align-items: center;
}
.evalTitle {
    font-size: 15px;
    font-weight: 600;
    color: #162533;
}
.evalTip {
    font-size: 13px;
    margin-left: 10px;
    color: #A4ACB4;
}
.hosInfo {
    margin-top: 20px;
    background-color: rgba(64,166,255,0.05);
}
.hosItem {
    padding: 14px 15px;
    display: inline-flex;
    width: 100%;
}
.hosImg {
    width: 63px;
    height: 48px;
    margin-right: 10px;
    overflow: hidden;

}
.hosImg :global .ant-image-img {
    width:100%;
    height: 100%;
    object-fit:cover;
}
.hosAdsWrap {
    display: flex;
    flex: 1;
    flex-direction: column;
}
.hosAds {
    width: 100%;
    margin-top: 12px;
    display: inline-flex;
    justify-content: space-between;
}
.hosName {
    flex: 1;
    font-size: 15px;
    font-weight: 600;
}
.hosLever {
    font-size: 12px;
    color: #A4ACB4;
}
.noteTypeWrap {
    display: inline-flex;
    flex-wrap: wrap;
    margin-top: 16px;
}
.noteTag {
    background-color: #F5F7FA;
    border-radius: 18px;
    height: 22px;
    padding: 0 9px;
    margin: 5px 20px 0 0;
    display: inline-flex;
    align-items: center;
}
.noteType {
    font-size: 13px;
    color: #A4ACB4;
}
.process {
    margin-top: 16px;
}
.lineLabel {
    font-size: 15px;
    font-weight: bold;
}
.lineText {
    font-size: 13px;
    color: #696E7E;
    margin-left: 3px;
}
.noteTitleView {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    margin: 26px 0;
    width: -webkit-fill-available;
}
.noteTitleSpe {
    height: 1px;
    width: 110px;
    background-color: #E5E5E5;
}
.bgLiner {
    position: absolute;
    bottom: 4px;
    height: 8px;
    width: 50px;
    background: linear-gradient(270deg, rgba(255,181,108,0) 0%, #FF681D 100%);
}
.titleText {
    font-size: 18px;
    font-weight: bold;
}

.first-contain {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    padding-top: 100px;
}

.first-wrap {
    width: 100%;
    height: 100%;
    position: relative;
}
.first-left{
    background: transparent;
    position: absolute;
    left: 0;
    top: 18%;
    z-index: 99;
    
}

.first-title-wrap {
    animation: firsttitle 2s;
    -webkit-animation: firsttitle 2s;
}

@keyframes firsttitle {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes firsttitle {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media screen and (min-width: 768px) {
    .first-title {
        text-align: left;
        font-size:40px;
        color: #333333;
        font-weight: 600;;
    }
    .first-img-wrap img {
        width: 546px;
        height: 644px;
    }
    .first-sub {
        text-align: left;
        font-size: 20px;
        color: #333333;
        margin-top: 0.5em;
    }
    .first-img-wrap {
        position: absolute;
        right: 2%;
        bottom: 0%;
    
    }

}

@media screen and (min-width: 1441px) {
    .first-title {
        text-align: left;
        font-size: 50px;
        color: #333333;
        font-weight: 600;;
    }
    .first-img-wrap img {
        width: 585px;
        height: 698px;
    }
    .first-sub {
        text-align: left;
        font-size: 24px;
        color: #333333;
        margin-top: 0.5em;
    }
    .first-img-wrap {
        position: absolute;
        right: 2%;
        bottom: 2%;
    
    }
}




.first-download {
    display: flex;
    margin-top: 3em;

}

.first-download-item {
    display: flex;
    flex-direction: column;
}

.first-ios-download {
    display: inline-block;
    width:200px;
    height: 50px;
    font-size: 20px;
    color: #fff;
    text-align: center;
    line-height: 50px;
    background-color: #40A6FF;
    border-radius: 8px;
    margin-right: 0.5em;
    border: none;
    outline: none;
    cursor:pointer;
}


.first-android-download{
    display: inline-block;
    width:200px;
    height: 50px;
    font-size: 20px;
    color: #fff;
    text-align: center;
    line-height: 50px;
    background-color: #59CDFF;
    border-radius: 8px;
    border: none;
    outline: none;
    cursor:pointer;
}
.first-android-icon,
.first-ios-icon {
    width: 12em;
    height: 12em;
    margin-top: 2em;
}
/* .first-download-item .first-ios-download :hover {
    background-color: rgb(15,143,255);
}
.first-download-item .first-android-icon :hover {
    background-color: rgb(0,178,255);
} */



.four-contain {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    padding-top: 100px;
}
.fourth-wrap {
    width: 100%;
    height: 100%;
    position: relative;
}



.fourth-right {
    position: absolute;
    right: 0;
    top: 30%;
}



.fourth-title-wrap {
    animation: fourthtitle 2s;
    -webkit-animation: fourthtitle 2s;
}

@keyframes fourthtitle {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fourthtitle {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media screen and (min-width: 768px) {
    .fourth-img-wrap {
        position: absolute;
        left: -5%;
        top: 0;
        
    }
    .fourth-img-wrap img {
        width: 528px;
        height: 670px;
    }
    .fourth-title {
        text-align: left;
        font-size: 60px;
        color: #40A6FF;
        font-weight: 600;;
    }
    
    .fourth-sub {
        text-align: left;
        font-size: 24px;
        color: #333333;
        margin-top: 0.5em;
    }
}

@media screen and (min-width: 1441px) {
    .fourth-img-wrap {
        position: absolute;
        left: -5%;
        top: 5%;
    }
    
    .fourth-img-wrap img {
        width: 528px;
        height: 670px;
    }
    .fourth-title {
        text-align: left;
        font-size: 80px;
        color: #40A6FF;
        font-weight: 600;;
    }
    
    .fourth-sub {
        text-align: left;
        font-size: 30px;
        color: #333333;
        margin-top: 0.5em;
    }
}
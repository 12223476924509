.third-contain {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    padding-top: 100px;
}
.third-wrap {
    width: 100%;
    height: 100%;
    position: relative;
}



.third-img-wrap img {
    width: 495px;
    height: 605px;
}

.third-left {
    position: absolute;
    left: 0;
    top: 30%;
    z-index: 9;
}



.third-title-wrap {
    animation: thirdtitle 2s;
    -webkit-animation: thirdtitle 2s;
}

@keyframes thirdtitle {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes thirdtitle {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media screen and (min-width: 768px) {
    .home-content {
        max-width: 1000px;
        margin: 0 auto;
    }
    #fp-nav.fp-right {
        right: 9%;
    }
    .third-img-wrap {
        position: absolute;
        right: 0%;
        top: 5%;
    }
    .third-title {
        text-align: left;
        font-size: 60px;;
        color: #40A6FF;
        font-weight: 600;;
    }
    
    .third-sub {
        text-align: left;
        font-size: 24px;
        color: #333333;
        margin-top: 0.5em;
    }
}

@media screen and (min-width: 1441px) {
    #fp-nav.fp-right {
        right: 10%;
    }
    .home-content {
        max-width: 1200px;
        margin: 0 auto;
    }
    .third-img-wrap {
        position: absolute;
        right: 0%;
        top: 10%; 
    }
    .third-title {
        text-align: left;
        font-size: 80px;;
        color: #40A6FF;
        font-weight: 600;;
    }
    
    .third-sub {
        text-align: left;
        font-size: 30px;
        color: #333333;
        margin-top: 0.5em;
    }
}
.to_top_view {
    width: 50px;
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 100px;
    right: 100px;
    z-index: 99;
}
.download_wrap {
    width: 50px;
    height: 50px;
    position: relative;
    
}
.download_item {
    display: flex;
    padding: 21px;
    background-color: white;
    position: absolute;
    bottom: 0;
    left: -430px;
}
.item {
    margin-right: 28px;
}
.title {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #162533;
    line-height: 28px;
    margin-bottom: 12px;
}
.download_icon {
    width: 180px;
    height: 180px;
}

.fillIn ,
.to_top,
.download {
    width: 50px;
    height: 50px;
    cursor:pointer;
}

.to_top {
    margin-top: 20px;
}
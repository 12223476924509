html,body{
  height: 100%;
  margin: 0;
}
.ant-list-item {
  border: none !important;
  padding: 0!important;
}
body {
  background-color: rgb(220,223,230,0.3) !important;
}
.container {
  width: 100%;
  height: 100%;
  padding-top: 100px;
  overflow: auto;
}
.contain_view {
  margin: 0 auto;
  width: 1200px;
  height: 800px;
  background: #FFFFFF;
  border-radius: 8px;
}
.scroll_view {
  width: 100%;
  height: 700px;
  overflow: auto;

}
.title {
  margin: 0 auto;
  width: 1150px;
  font-size: 16px;
  color: #A4ACB4;
  line-height: 22px;
  padding-left: 50px;
  padding-top: 26px;
  padding-bottom: 17px;
  background-color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.img_view {
  padding-left: 50px;
  padding-bottom: 34px;
  background-color: white;
}
.img_header {
  width: 1100px;
  padding-right: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.img_title {
  font-size: 20px;
  font-weight: 600;
  color: #162533;
  line-height: 28px;
}
.btn_wrap {
  display: flex;
  align-items: center;
}
.mg_btn {
  width: 60px;
  height: 30px;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  color: #696E7E;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #A4ACB4;
  outline: none;
  padding: 0;
  margin: 0;
  cursor:pointer;
}
.del_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 30px;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #A4ACB4;
  cursor:pointer;
}
.del_icon {
  width: 17px;
  height: 15px;
  margin-right: 3px;
}
.del_txt {
  font-size: 14px;
  color: #696E7E;
  line-height: 20px;
}
.select_btn {
  width: 60px;
  height: 30px;
  line-height: 28px;
  font-size: 14px;
  text-align: center;
  color: #696E7E;
  background-color: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #A4ACB4;
  outline: none;
  margin-right: 10px;
  cursor:pointer;
}
.timer {
  font-size: 16px;
  color: #A4ACB4;
  line-height: 22px;
  padding-bottom: 5px;
}
.img_wrap {
  display: flex;
  flex-wrap: wrap;
}
.img_item {
  position: relative;
  padding-right: 16px;
  padding-bottom: 16px;
}
.img_del {
  width: 170px;
  height: 170px;
}
.select_icon {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 26px;
  cursor:pointer;
}
.loading_wrap {
  display: flex;
  flex: 1;
  justify-content: center;
}
.loadding_icon {
  width: 35px;
  height: 35px;
}
.emptyView {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
}
.emptyIcon {
  width: 331px;
  height: 196px;
  margin-top: 208px;
}
.emptyText {
  font-size: 16px;
  color: #162533;
  line-height: 22px;
  margin-top: 10px;
}
.big_load {
  width: 140px;
  height: 140px;
  margin-top: 200px;
}


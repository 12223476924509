.reply_item {
    width: 100%;
    margin-top: 28px;
}
.header_view {
    display: flex;
    align-items: center;
}
.avatar {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-right: 12px;
}
.name_wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.reply_wrap {
    display: flex;
    margin-top: 10px;
    padding-left: 52px;
}
.reply_tip {
    font-size: 16px;
    font-weight: 600;
    color: #162533;
    line-height: 22px;
}
.reply_del {
    font-size: 16px;
    color: #162533;
    line-height: 22px;
}
.like_reply {
    display: flex;
    align-items: center;
    padding-left: 52px;
    margin-top: 10px;
}
.like_wrap {
    display: flex;
    align-items: center;
    margin-right: 48px;
    cursor:pointer;
}
.like_icon {
    width: 16px;
    height: 14px;
    margin-right: 6px;
}
.like_text {
    font-size: 14px;
    color: #162533;
    line-height: 20px;
    cursor:pointer;
}
.input_wrap {  
    margin-left: 52px;
    margin-top: 15px;     
}
.btn_wrap {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}
.btn_item {
    width: 120px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    color: white;
    text-align: center;
    background-color: #40A6FF;
    border-radius: 4px;
    padding: 0;
    margin-left: 20px;
    border: none;
    outline: none;
    cursor:pointer;
}
.ReactModalPortal .ReactModal__Overlay.ReactModal__Overlay--after-open{
  background-color: transparent !important;
  z-index: 9999;
}
.modal_view {
  width: 391px;
  height: 340px;
  background: #ffffff;
  border-radius: 8px;
}
.close_wrap {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.close_item {
  padding: 16px;
  padding-bottom: 0;
  margin-top: 10px;
  margin-right: 11px;
  margin-bottom: 2px;
}
.close_icon {
  width: 16px;
  height: 16px;
}
.content_wrap {
  padding-left: 35px;
  padding-right: 35px;
}
.title {
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 26px;
}
.input_wrap {
  width: 319px;
  height: 50px;
  border: 1px solid #DCDFE6;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  overflow: hidden;
}
.num_input {
  width: 305px;
  height: 50px;
  font-size: 18px;
  color: #162533;
  border: none;
  outline: none;
  padding: 0;
  padding-left: 14px;
}
.input_wrap_bottom {
  display: flex;
  align-items: center;
  width: 319px;
  height: 50px;
  border: 1px solid #DCDFE6;
  border-top: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
}
.code_input {
  width: 203px;
  height: 50px;
  font-size: 18px;
  color: #162533;
  border: none;
  outline: none;
  padding: 0;
  padding-left: 14px;
}
.line_icon {
  width: 1px;
  height: 20px;
  background-color: #DCDFE6;
}
.code_btn {
  width: 100px;
  height: 50px;
  font-size: 14px;
  color: #162533;
  background-color: transparent;
  border: none;
  outline: none;
}
.err_tip {
  height: 17px;
  font-size: 12px;
  font-weight: 600;
  color: #F16765;
  line-height: 17px;
  margin-top: 11px;
}
.submit_btn {
  width: 319px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 16px;
  color: #FFFFFF;
  background: #40A6FF;
  border-radius: 4px;
  border: none;
  outline: none;
  margin-top: 15px;
}